import React, { ReactElement } from "react";
import styles from "./../index.module.scss";
import word from "../../../../../components/word";
const Type = (props): ReactElement => {
    return (
        <>
            <div className={styles.menu}>
                <div className={styles.menu_listok}></div>
                <div className={styles.menu_block}>
                    <b>{props.current.Name}</b> - {props.current.NumberDays} {word(props.current.NumberDays, ["день", "дня", "дней"])}
                    {props.current.ID === "af3d2a4e-e1d2-11e8-80bc-00155d545503" ? (
                        <span>
                            <br /> · Отпуск присоединяется к любому периоду основного отпуска{" "}
                        </span>
                    ) : null}{" "}
                    {props.current.ID === "9cc33f5a-a154-11e9-80bf-00155d9b230c" ? (
                        <span>
                            <br /> · Отпуск присоединяется к любому периоду основного отпуска{" "}
                        </span>
                    ) : null}{" "}
                    {props.current.ID === "95c39a82-01c2-11e8-80c6-00155d545500" ? (
                        <span>
                            <br />
                        </span>
                    ) : null}
                    {props.current.ID === "4e9b9a52-5773-11e7-80d7-40f2e9a19d93" ? (
                        <span>
                            <br /> · Обязательно 1 период должен быть не менее 14 дней
                        </span>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default Type;
