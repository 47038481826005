import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import TimeOffList from "./components/TimeOffList";
import { ITimeOffData, ITimeOffDataUser } from "../../typec/ITimeOff";
import Button from "../../components/Button";
import VacationCheck2 from "../Vacation/components/VacationCheck/index2";
import SlideScreen from "../../components/SlideScreen";
import { InputError } from "../../components/Inputs";

const TimeOff = (): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(true);
    const [loadBtn, setLoadBtn] = useState(false);
    const [error, setError] = useState("");
    const [userData, setUserData] = useState<ITimeOffDataUser>();
    const [checkScreen, setCheckScreen] = useState(false);
    const [viewBtn, setViewBtn] = useState(false);
    const [viewBtnPut, setViewBtnPut] = useState(true);

    const navigate = useNavigate();
    const prev = () => {
        if (checkScreen) setCheckScreen(false);
        else navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const createError = (el) => {
        setError(el);
    };

    const clearError = () => {
        setError("");
    };

    const editDataList = (element) => {
        setUserData(() => {
            const newData = userData?.list?.map((el) => {
                if (el.forDay == element.forDay) {
                    return element;
                } else return el;
            });
            return {
                ...userData,
                list: newData,
            };
        });
        setViewBtn(true);
    };

    const editData = (key, element) => {
        setUserData({
            ...userData,
            [key]: element,
        });
    };

    const getJSONData = (json) => {
        setUserData(() => {
            const newList: ITimeOffData = json.dayoffs?.map((el) => {
                return {
                    ID: el.ID,
                    startDay: el.Date,
                    transferDay: el.DateTransfer,
                    forDay: el.WorkDate,
                    status: !el.Changed,
                    maxDateTransfer: el.MaxDateTransfer,
                    minDateTransfer: el.MinDateTransfer,
                    replaceTransferButton: el.ReplaceTransferButton,
                };
            });
            const data = {
                res: json.res,
                count: `${newList.length}`,
                comment: "",
                dayoff_transfer: json.dayoff_transfer,
                list: newList,
            };
            return data;
        });
    };

    const postDataTimeOff = (data?: ITimeOffData, comment = "") => {
        setLoadBtn(true);
        const dayoffs = data?.map((el) => {
            return {
                ID: el.ID,
                WorkDate: el.forDay,
                DateTransfer: el.transferDay,
            };
        });
        fetch("/dev/vacation_dayoff_new", {
            method: "POST",
            body: JSON.stringify({
                Dayoffs: dayoffs,
                Comment: comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    editData("data", json.Date);
                    setCheckScreen(true);
                } else {
                    createError(json.Error);
                }
                setLoadBtn(false);
            });
    };

    const putDataTimeOff = async (data?: ITimeOffData, comment?: string) => {
        setLoadBtn(true);
        const dayoffs = data?.map((el) => {
            return {
                ID: el.ID,
                WorkDate: el.forDay,
                DateTransfer: el.transferDay,
            };
        });
        await fetch("/dev/vacation_dayoff_new", {
            method: "POST",
            body: JSON.stringify({
                Dayoffs: dayoffs,
                Comment: comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        await fetch("/dev/vacation_dayoff_new", {
            method: "PUT",
            body: JSON.stringify({}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                if (json.res) {
                    setLoad(true);
                    setCheckScreen(false);
                    setViewBtn(false);
                } else {
                    setCheckScreen(false);
                    createError(json.Error);
                }
                setLoadBtn(false);
            });
    };

    useEffect(() => {
        if (load) {
            fetch(`/dev/vacation_dayoff_new?do_date=${""}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        getJSONData(json);
                    }
                    setLoad(false);
                });
        }
    }, [load]);

    const getCheckListPeriods = (list) => {
        const newList = list?.map((el) => {
            if (el.transferDay != "") {
                return [`c ${el.startDay} на ${el.transferDay}`];
            }
        });
        newList?.unshift(["Перенос отгула"]);
        return newList;
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <>
                        {checkScreen ? (
                            <div className={styles.hero_check}>
                                <div className={styles.hero_check_container}>
                                    <VacationCheck2
                                        statementDay={userData?.data}
                                        disableNo={true}
                                        nameStaff={context.userdata.name}
                                        supervisor={context.userdata.boss_name}
                                        typeVacation="перенос отгула"
                                        itemDetails={getCheckListPeriods(userData?.list)}
                                        writeComment={true}
                                        writeCommentEdit={editData}
                                        nameEditItemComment="comment"
                                        valueComment={userData?.comment}
                                        onBlur={() => setViewBtnPut(true)}
                                        onFocus={() => setViewBtnPut(false)}
                                    />
                                    {viewBtnPut ? (
                                        <SlideScreen altitudeLevel="service">
                                            <Button
                                                load={loadBtn}
                                                type="save_not_margin"
                                                onClick={() => {
                                                    putDataTimeOff(userData?.list, userData?.comment);
                                                }}
                                            >
                                                Отправить на согласование
                                            </Button>
                                            <Button
                                                type="cancel_grey"
                                                onClick={() => {
                                                    setCheckScreen(false);
                                                }}
                                            >
                                                Отмена
                                            </Button>
                                        </SlideScreen>
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            <div className={styles.hero}>
                                <p className={styles.hero_main_heading}>Отгул</p>
                                {userData?.count == "0" || userData?.res == false ? (
                                    <Notification icon="info" type="info">
                                        У вас пока нет доступных отгулов
                                    </Notification>
                                ) : (
                                    <>
                                        <p className={styles.hero_second_title}>Перенесите дату отгула при необходимости.</p>
                                        <div className={styles.hero_null_count_text}>
                                            На сегодня доступно дней: <a>{userData?.count}</a>
                                        </div>
                                        <TimeOffList clearError={clearError} userData={userData} editData={editDataList} />
                                        <div style={{ height: "140px", width: "100%" }} />
                                        {viewBtn ? (
                                            <>
                                                <SlideScreen altitudeLevel="service">
                                                    {error ? <InputError titleText={error} /> : null}
                                                    <Button load={loadBtn} type="save_not_margin" onClick={() => postDataTimeOff(userData?.list)}>
                                                        Сформировать заявление
                                                    </Button>
                                                </SlideScreen>
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        )}
                    </>
                </>
            )}
        </>
    );
};

export default TimeOff;
