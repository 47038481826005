import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import VacationCheckItem from "../VacationCheckItem";
import { IVacationCheckList2 } from "../../../../../../typec/IVacations";
import VacationCheckItemDetails from "../VacationCheckItemDetails";
import { InputTextArea } from "../../../../../../components/Inputs";
import ImageItem from "../../../../../ClietSupport/NewAppeal/components/ImageItem";
import NewPhotoButtonLimit from "../../../../../ClietSupport/NewAppeal/components/NewPhotoButtonLimit";

const VacationCheckList2 = (props: IVacationCheckList2): ReactElement => {
    const {
        fullName,
        position,
        subdivision,
        supervisor,
        typeVacation,
        quantityDay,
        period,
        quantitySposob,
        startDay,
        periodArr,
        periodArrNew,
        commentary,
        dataUvolneniya,
        prichinaUvolneniya,
        commentaryBoss,
        dataUvolneniyaBoss,
        prichinaUvolneniyaBoss,
        kolichestvoEkzemplyarov,
        mestoVydachiSpravki,
        opisanieMestaVydachiSpravki,
        periodSpravki,
        sposobPolucheniyaSpravki,
        vidSpravki,
        statusCommentary,
        vidPersonalnyhDannyh,
        prichinaIzmeneniyaPersonalnyhDannyh,
        coordination,
        itemDetails,
        status,
        writeComment = false,
        writeCommentEdit,
        nameChildren,
        photoArr,
        nameEditItemComment,
        valueComment,
        onBlur = () => null,
        onFocus = () => null,
        commentaryStaff,
        photoAdd,
        photoConfirmation = true,
        photoError,
        cardNumber,
        dataUvolneniyaZhelayemaya,
    } = props;

    return (
        <div className={styles.item_container}>
            {fullName ? <VacationCheckItem title="ФИО сотрудника" info={fullName} /> : null}
            {typeof nameChildren == "undefined" ? null : typeof nameChildren == "string" ? (
                <VacationCheckItem title="ФИО ребенка" info={nameChildren} />
            ) : nameChildren[0] != "" ? (
                <VacationCheckItemDetails title="Отпуск по уходу за ребенком" info={nameChildren} typeVacation="" />
            ) : null}
            {cardNumber ? <VacationCheckItem title="Информация о карте" info={cardNumber} /> : null}
            {position ? <VacationCheckItem title="Должность" info={position} /> : null}
            {subdivision ? <VacationCheckItem title="Подразделение" info={subdivision} /> : null}
            {supervisor && !coordination ? <VacationCheckItem title="Руководитель" info={supervisor} /> : null}
            {/* {typeVacation && !itemDetails ? <VacationCheckItem title="Тип заявления" info={typeVacation} /> : null} */}
            {quantityDay && !coordination ? <VacationCheckItem title="Количество дней" info={quantityDay} /> : null}
            {quantitySposob ? <VacationCheckItem title="Способ получения" info={quantitySposob} /> : null}
            {startDay !== "01.01.1" && startDay !== "" && startDay && !coordination ? <VacationCheckItem title="Дата начала" info={startDay} /> : null}
            {period == "01.01.1" ? <VacationCheckItem title="Период отпуска" info={period} /> : null}
            {periodArr != undefined && periodArr != "" && !coordination ? <VacationCheckItem title="Период отпуска" info={periodArr} /> : null}
            {periodArrNew != undefined && periodArrNew != "" && !coordination ? <VacationCheckItem title="Период отпуска" info={periodArrNew} /> : null}
            {prichinaUvolneniya ? <VacationCheckItem title="Причина увольнения" info={prichinaUvolneniya} /> : null}
            {commentary ? <VacationCheckItem title="Подробности" info={commentary} /> : null}
            {dataUvolneniyaZhelayemaya ? <VacationCheckItem title="Желаемая дата увольнения" info={dataUvolneniyaZhelayemaya} /> : null}
            {/* {dataUvolneniya ? dataUvolneniya === dataUvolneniyaZhelayemaya ? null : <VacationCheckItem title="Новая дата увольнения" info={dataUvolneniya} /> : null} */}
            {dataUvolneniya ? (
                status == "На согласовании руководителя" || status == "Активна" ? null : dataUvolneniya === dataUvolneniyaZhelayemaya ? (
                    <VacationCheckItem title="Дата увольнения" info={dataUvolneniya} />
                ) : (
                    <VacationCheckItem title="Новая дата увольнения" info={dataUvolneniya} />
                )
            ) : null}
            {prichinaUvolneniyaBoss || commentaryBoss || dataUvolneniyaBoss ? <div className={styles.item_container_line} /> : null}
            {prichinaUvolneniyaBoss ? <VacationCheckItem title="Причина увольнения" info={prichinaUvolneniyaBoss} /> : null}
            {commentaryBoss ? <VacationCheckItem title="Подробности" info={commentaryBoss} /> : null}
            {dataUvolneniyaBoss ? <VacationCheckItem title="Дата увольнения" info={dataUvolneniyaBoss} /> : null}
            {vidSpravki ? <VacationCheckItem title="Вид справки" info={vidSpravki} /> : null}
            {periodSpravki ? <VacationCheckItem title="Период справки" info={periodSpravki} /> : null}
            {sposobPolucheniyaSpravki ? <VacationCheckItem title="Способ получения" info={sposobPolucheniyaSpravki} /> : null}
            {kolichestvoEkzemplyarov ? <VacationCheckItem title="Количество экземпляров" info={`${kolichestvoEkzemplyarov}`} /> : null}
            {vidPersonalnyhDannyh ? <VacationCheckItem title="Вид персональных данных" info={vidPersonalnyhDannyh} /> : null}
            {prichinaIzmeneniyaPersonalnyhDannyh ? <VacationCheckItem title="Причина изменения" info={prichinaIzmeneniyaPersonalnyhDannyh} /> : null}
            {itemDetails ? (
                <VacationCheckItemDetails
                    title="Подробная информация"
                    info={itemDetails}
                    typeVacation={typeVacation !== "Увольнение" && typeVacation !== "Перечисление зарплаты на карту" && typeVacation !== "перенос отгула" ? typeVacation : ""}
                />
            ) : null}
            {mestoVydachiSpravki || opisanieMestaVydachiSpravki ? (
                <VacationCheckItem title="Место выдачи справки" info={`${mestoVydachiSpravki}, ${opisanieMestaVydachiSpravki}`} />
            ) : null}
            {statusCommentary ? <VacationCheckItem title="Комментарий" info={status == "Отменяется ОКА" ? `${status}: ${statusCommentary}` : statusCommentary} /> : null}
            {photoArr && !photoAdd ? (
                <>
                    <p className={styles.item_container_text_item}>{photoConfirmation ? "Фото подтверждающих документов" : "Фото недостающих документов"}</p>
                    <div className={styles.item_photos}>
                        {photoArr.map((el, index) => (
                            <ImageItem key_photo={`check_img_${index}`} src={el.data} deleteBtn={false} />
                        ))}
                    </div>
                </>
            ) : null}
            {photoAdd && !photoArr ? (
                <>
                    <p className={styles.item_container_text_item}>{photoConfirmation ? "Фото подтверждающих документов" : "Фото недостающих документов"}</p>
                    <div className={styles.item_photos}>
                        <NewPhotoButtonLimit onChange={(value) => photoAdd(value)} limit={20} />
                    </div>
                    {photoError ? <p className={styles.item_container_text_error}>{photoError}</p> : null}
                </>
            ) : null}
            {commentaryStaff ? <VacationCheckItem title="Комментарий сотрудника" info={commentaryStaff} /> : null}
            {writeComment ? (
                <>
                    <p className={styles.item_container_text_item}>Комментарий</p>
                    <InputTextArea
                        onBlur={() => onBlur()}
                        onFocus={() => onFocus()}
                        type="area"
                        placeholder="Напишите комментарий"
                        editData={writeCommentEdit}
                        name={nameEditItemComment}
                        value={valueComment}
                    />
                </>
            ) : null}
        </div>
    );
};

export default VacationCheckList2;
