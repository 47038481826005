import React, { ReactElement } from "react";
import Type from "../Type";

const Types = (props): ReactElement => {
    return (
        <>
            <div>
                {props.data.map((list) => (
                    <Type userData={props.userData} setData={props.setData} load={props.load} setLoad={props.setLoad} next={props.next} current={list} key={list.ID} />
                ))}
            </div>
        </>
    );
};

export default Types;
